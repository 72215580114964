@font-face {
  font-family: 'publicsans-regular';
  src: url('fonts/publicsans-regular.ttf') format('truetype');
}
@font-face {
  font-family: 'publicsans-light';
  src: url('fonts/publicsans-light.ttf') format('truetype');
}
@font-face {
  font-family: 'publicsans-medium';
  src: url('fonts/publicsans-medium.ttf') format('truetype');
}
@font-face {
  font-family: 'publicsans-bold';
  src: url('fonts/publicsans-bold.ttf') format('truetype');
}
@font-face {
  font-family: 'publicsans-extrabold';
  src: url('fonts/publicsans-extrabold.ttf') format('truetype');
}

html,
body,
div,
span,
main,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strong,
sub,
sup,
var,
b,
u,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  display: block;
}

a {
  color: inherit;
}

b,
strong {
  font-weight: 900;
}

i,
em {
  font-style: italic;
}

template {
  display: none;
}

html,
body {
  background: #fff;
  color: #3d3d3d;
  font-family: 'publicsans-regular', Arial, sans-serif;
  font-size: 10px;
  line-height: 1.25;
}

body {
  font-size: 2rem;
}

h1,
h2,
h3,
h4 {
  font-family: 'publicsans-extrabold', Arial, sans-serif;
  line-height: 1;
}
h1 {
  font-size: 4.5rem;
  padding-bottom: 2rem;
}
h2 {
  font-family: 'publicsans-light', Arial, sans-serif;
  font-size: 3.5rem;
  line-height: 1.25;
  padding-bottom: 2rem;
}
h3 {
  font-size: 2.5rem;
  padding-bottom: 2rem;
}
h4 {
  font-size: 2.2rem;
}

p {
  padding-bottom: 2rem;
}

iframe {
  display: block;
}

hr {
  margin: 2rem 0;
  border: 0;
  border-bottom: 1px solid #ccc;
}

.columns + hr {
  margin-bottom: 6rem;
}

label,
input[type='text'],
input[type='email'] {
  display: block;
}

.button,
.button-phone,
.button-contact,
.button-form {
  display: inline-block;
  position: relative;
  padding: 2rem 3rem;
  font-size: 2.5rem;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  background: #fff;
  border: 2px solid #93debe;
  cursor: pointer;
}
.button-phone {
  padding-left: 7.5rem;
}
.button-contact,
.button-form {
  padding-left: 8.5rem;
}
.button-phone::before,
.button-contact::before,
.button-form::before {
  display: inline-block;
  width: 3.5rem;
  height: 3.5rem;
  position: absolute;
  z-index: 1;
  top: 2rem;
  left: 3rem;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-attachment: scroll;
  background-size: contain;
  content: '';
}
.button-phone::before {
  background-image: url('images/icon-phone.svg');
}
.button-contact::before {
  background-image: url('images/icon-contact.svg');
}
.button-form::before {
  background-image: url('images/icon-form.svg');
}
.button-centered {
  position: relative;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}
.button:hover,
.button-phone:hover,
.button-contact:hover,
.button-form:hover {
  text-decoration: underline;
}

.wrapper {
  box-sizing: border-box;
  width: 100%;
  max-width: 148rem;
  margin: 0 auto;
  padding: 0 6rem;
}

.columns {
  display: flex;
  min-height: 100%;
}
.column {
  width: 100%;
  flex-basis: 0;
  flex-grow: 1;
}
.column-left,
.column-right {
  display: inline-block;
  box-sizing: border-box;
  width: 50%;
  vertical-align: top;
}
.column-left {
  padding-right: 4rem;
}
.column-right {
  padding-left: 4rem;
}

.main {
  min-height: 20rem;
  padding: 20rem 0 0;
  overflow: hidden;
}

.section {
  padding: 4rem 0;
  box-sizing: border-box;
}

.video-wrapper {
  width: 100%;
  height: 0;
  margin: 6rem 0 2rem;
  padding-bottom: 56.25%;
  position: relative;
  opacity: 0;
  animation: fade-in-left 0.5s forwards alternate 1 0.8s;
}
.video-wrapper iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}

.image-wrapper {
  width: 100%;
}
.image-wrapper img {
  width: 100%;
  width: calc(100% + 10rem);
}

.header {
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}
.header .column:last-child {
  flex-grow: 3;
}

.header-logo {
  display: block;
  width: 26rem;
  margin: 2rem 0;
  animation: fade-in 0.5s forwards normal 1 0s;
}
.header-logo img {
  width: 100%;
}

.header-navigation {
  margin-top: 10rem;
  text-align: right;
  opacity: 0;
  animation: fade-in 0.5s forwards alternate 1 0.2s;
}
.sub-navigation {
  padding-bottom: 6rem;
  text-align: right;
  opacity: 0;
  animation: fade-in 0.5s forwards alternate 1 0.4s;
}
.header-navigation li,
.sub-navigation li {
  display: inline-block;
  padding: 0 0.25rem;
}
.header-navigation li:last-child,
.sub-navigation li:last-child {
  padding-right: 0;
}
.navigation-item {
  display: block;
  padding: 0 1rem;
  font-size: 1.8rem;
  text-decoration: none;
}
.sub-navigation .navigation-item {
  font-size: 1.6rem;
}
.header-navigation li:last-child .navigation-item,
.sub-navigation li:last-child .navigation-item {
  padding-right: 0;
}
.navigation-item:hover {
  text-decoration: underline;
}
.navigation-item[aria-current='page'],
.navigation-item.current {
  font-family: 'publicsans-bold', Arial, sans-serif;
  color: #50bbff;
}

.header-social {
  margin: -8rem -0.5rem 0 0;
  text-align: right;
  opacity: 0;
  animation: fade-in 0.5s forwards alternate 1 0.2s;
}
.social-navigation li,
.header-social li {
  display: inline-block;
  padding: 0.35rem;
}
.social-item,
.header-social-item {
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
}
.social-item img,
.header-social-item img {
  display: inline-block;
  height: 80%;
  margin: 20% 0 0;
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  transition: transform 250ms ease-out;
}
.social-item:hover img,
.header-social-item:hover img {
  transform: translate3d(0, 0, 0) scale3d(1.25, 1.25, 1.25);
}
.header-social .header-contact-link {
  border: 2px solid #93debe;
  padding: 1rem 2rem;
  text-decoration: none;
}
.header-social .header-contact-link:hover {
  text-decoration: underline;
}

.header-skip {
  display: none;
}

.header-menu {
  display: none;
  width: 4rem;
  height: 4rem;
  position: absolute;
  z-index: 1;
  top: 2rem;
  right: 2rem;
  padding: 2rem 0;
  background-image: url('images/icon-menu.svg');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: contain;
  color: rgba(0, 0, 0, 0);
  font-size: 0;
  line-height: 2;
  text-indent: -123rem;
  overflow: hidden;
}

.section-hero {
  display: flex;
  min-height: 90rem;
  padding-bottom: 0;
}
.section-hero .column:first-child {
  display: flex;
}
.section-hero .column:last-child {
  flex-grow: 0.7;
}
.section-hero .hero-image {
  width: 75rem;
  height: 75rem;
  position: relative;
  margin-right: 4rem;
  background: #dbf8fc;
  border-radius: 50%;
  align-self: flex-end;
  opacity: 0;
  animation: fade-in-scale 0.5s forwards alternate 1 1s;
}
.section-hero .hero-image img {
  width: 100%;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 50%;
  margin-left: -50%;
  opacity: 0;
  animation: fade-in-up 0.5s forwards alternate 1 1.2s;
}
.section-hero h1 {
  font-size: 6rem;
  padding: 2rem 0 1rem;
  opacity: 0;
  animation: fade-in-left 0.5s forwards alternate 1 0.4s;
}
.section-hero p {
  padding-top: 1rem;
  font-size: 2.5rem;
  opacity: 0;
  animation: fade-in-left 0.5s forwards alternate 1 0.6s;
}

.section-graphic, 
.section-blog {
  padding: 8rem 0;
  position: relative;
  background: #f3fcf8;
  overflow: hidden;
}
.section-graphic::after,
.section-blog::after {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: -60rem;
  margin: -80rem 0 0 0;
  width: 160rem;
  height: 160rem;
  border-radius: 50%;
  background: #ecfaf8;
  content: '';
  opacity: 0;
  animation: fade-in-scale 0.5s forwards alternate 1 0s;
}
.section-graphic .wrapper,
.section-blog .wrapper {
  position: relative;
  z-index: 2;
}
.section-blog .blog-wrapper {
  float: left;
  width: 70%;
}
.section-blog .blog-sidebar {
  float: right;
  width: calc(30% - 4em);
  padding: 0 2em 1em;
}
.blog-sidebar ul {
  list-style: disc;
  margin: 0 0 2em 1em;
}

.blog-sidebar ul li {
  margin: 0.5em 0;
  text-transform: capitalize;
}
.section-blog img {
  max-width: 100%;
}
.section-process {
  padding-top: 10rem;
  text-align: center;
}
.section-process::after {
  animation-delay: 2s;
}
.section-process .wrapper {
  flex-direction: column;
}
.section-process h1 {
  padding-bottom: 2rem;
  opacity: 0;
  animation: fade-in 0.5s forwards alternate 1 1.6s;
}
.section-process .wrapper > p {
  max-width: 80rem;
  margin: 0 auto;
  font-size: 2.5rem;
  opacity: 0;
  animation: fade-in 0.5s forwards alternate 1 1.4s;
}
.section-process ol {
  display: flex;
  margin: 10rem -4rem 0;
  padding: 4rem 0;
  position: relative;
  opacity: 0;
  animation: fade-in 0.5s forwards alternate 1 2s;
  counter-reset: list-counter;
}
.section-process ol::before {
  border-top: 1px solid #ccc;
  width: 70%;
  height: 0;
  position: absolute;
  z-index: 1;
  top: -2.75rem;
  left: 15%;
  content: '';
}
.section-process ol li {
  flex-basis: 0;
  flex-grow: 1;
  margin: 0 4rem;
  padding: 4rem 3rem;
  position: relative;
  background: #fff;
  counter-increment: list-counter;
}
.section-process ol li::before {
  width: 6rem;
  height: 6rem;
  position: absolute;
  z-index: 2;
  top: -10rem;
  left: 50%;
  margin: 0 0 0 -3rem;
  line-height: 6rem;
  text-align: center;
  background: #fff;
  border: 2px solid #93debe;
  border-radius: 50%;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.25);
  content: counter(list-counter);
}
.section-process ol p {
  padding-top: 2rem;
  font-family: 'publicsans-light', Arial, sans-serif;
  font-size: 2rem;
}
.section-process ol img {
  display: inline-block;
  height: 10rem;
  margin: 0 auto 2rem;
}
.section-process ol h2 {
  padding: 0 4rem;
  font-family: 'publicsans-medium', Arial, sans-serif;
  font-size: 2.5rem;
}

.subsection-process-gameplan,
.subsection-process-lifeplan {
  display: flex;
  align-items: center;
  padding: 6rem 0;
  text-align: left;
}
.section-image {
  width: 85rem;
  height: 60rem;
  margin: 0 4rem 0 0;
  overflow: hidden;
}
.section-image img {
  width: 100%;
}
.subsection-process-gameplan h2,
.subsection-process-lifeplan h2 {
  font-size: 4.5rem;
}
.subsection-process-lifeplan h3 {
  font-size: 3.5rem;
}
.subsection-process-gameplan p {
  padding: 0 0 4rem;
  font-family: 'publicsans-light', Arial, sans-serif;
}
.subsection-process-gameplan .section-image {
  margin-left: -6rem;
}
.subsection-process-lifeplan {
}
.subsection-process-lifeplan .section-image {
  height: 80rem;
  margin: 0 -6rem 0 4rem;
}
.subsection-process-lifeplan .column > ul {
  font-family: 'publicsans-light', Arial, sans-serif;
  font-size: 1.8rem;
  line-height: 2;
}
.subsection-process-lifeplan li li::before {
  content: '— ';
}
.subsection-process-lifeplan li li:last-child {
  margin-top: 0.5rem;
}
.subsection-process-lifeplan li li:last-child::before {
  content: '';
}
.subsection-process-lifeplan ul a {
  font-family: 'publicsans-light', Arial, sans-serif;
  text-decoration: none;
}
.subsection-process-lifeplan ul a:hover {
  text-decoration: underline;
}
.subsection-process-lifeplan ul a::after {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
  position: relative;
  top: 0.4rem;
  background-image: url('images/icon-arrow-right-blue.svg');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: contain;
  content: '';
  transform: translate3d(0, 0, 0);
  transition: transform 250ms ease-out;
}
.subsection-process-lifeplan ul a:hover::after {
  transform: translate3d(0.5rem, 0, 0);
}
.subsection-process-lifeplan .column > ul > li {
  padding-bottom: 4rem;
}
.subsection-process-lifeplan .column > ul > li:last-child {
  padding-bottom: 0;
}

.section-featured {
  text-align: center;
}
.section-featured .wrapper {
  flex-direction: column;
}
.section-featured h1 {
  font-family: 'publicsans-normal', Arial, sans-serif;
  font-size: 2.5rem;
  padding: 2rem 0 6rem;
}
.section-featured h1 em {
  font-style: italic;
  font-family: 'publicsans-bold', Arial, sans-serif;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
}
.grid a {
  display: block;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  background: #f5fcfd;
  border: 1px solid #ccc;
  font-family: 'publicsans-light', Arial, sans-serif;
  text-decoration: none;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  transition: box-shadow 250ms ease-out;
}
.grid a:hover {
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.5);
}
.grid a img {
  display: block;
  max-width: 75%;
  max-height: 70%;
  line-height: 15;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.grid a > span {
  width: 100%;
  position: absolute;
  z-index: 1;
  bottom: 1rem;
  left: 0;
}
.grid a:hover > span {
  width: 100%;
  position: absolute;
  z-index: 1;
  bottom: 1rem;
  left: 0;
  text-decoration: underline;
}
.grid a > span span::after {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
  position: relative;
  top: 0.25rem;
  background-image: url('images/icon-arrow-right-blue.svg');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: contain;
  content: '';
  transform: translate3d(0, 0, 0);
  transition: transform 250ms ease-out;
}
.grid a:hover > span span::after {
  transform: translate3d(0.5rem, 0, 0);
}

.section-content {
  font-family: 'publicsans-light', Arial, sans-serif;
  opacity: 0;
  animation: fade-in 0.5s forwards alternate 1 0s;
}
.wrapper > .section-content {
  padding: 0;
}
.section-content .columns {
  margin: 0 -4rem;
}
.section-content .columns .columns {
  min-height: 0;
}
.section-content .column {
  padding: 0 4rem;
  opacity: 0;
  animation: fade-in-left 0.5s forwards alternate 1 0.4s;
}
.section-content .column a.bold-link {
  font-size: 2.5rem;
  color: #50bbff;
}
.section-content .column:last-child:not(:first-child) {
  opacity: 0;
  animation: fade-in-left 0.5s forwards alternate 1 0.6s;
}
.section-content h1 {
  padding-bottom: 4rem;
  opacity: 0;
  animation: fade-in-left 0.5s forwards alternate 1 0.2s;
}
.section-content h1 + p > img:first-child {
  margin: 1rem 0 0;
}
.section-content h2 {
  opacity: 0;
  animation: fade-in-left 0.5s forwards alternate 1 0.4s;
}
.section-content h2 + h3 {
  padding-top: 2rem;
}
.section-content p {
  padding-bottom: 4rem;
  line-height: 1.5;
}
.section-content .column ul,
.section-content .column ol {
  padding-bottom: 2rem;
  opacity: 0;
  animation: fade-in-left 0.5s forwards alternate 1 0.4s;
}
.section-content .column li {
  margin: 0 0 0 2.5rem;
  padding: 0 0 2rem;
  line-height: 1.5;
}
.section-content .column ul li {
  list-style: outside disc;
}
.section-content .column ol li {
  list-style: decimal;
}
.section-content blockquote p {
  font-family: 'publicsans-light', Arial, sans-serif;
  font-size: 4.5rem;
  line-height: 1.25;
  padding-bottom: 2rem;
}
.section-content .column > img,
.section-content .column p > img {
  max-width: 100%;
  margin: 6rem 0 2rem;
  opacity: 0;
  animation: fade-in-left 0.5s forwards alternate 1 0.4s;
}
.section-content .column p:first-child > img {
  margin-top: 2rem;
}
.section-content .column:first-child:not(:last-child) > img,
.section-content .column:first-child:not(:last-child) p > img {
  max-width: calc(100% + 12rem);
  margin-left: -12rem;
}
.section-content .column:last-child:not(:first-child) > img,
.section-content .column:last-child:not(:first-child) p > img {
  max-width: calc(100% + 12rem);
  animation-delay: 0.8s;
}
.section-content .column .logo-wrapper {
  display: flex;
}
.section-content .column .logo-wrapper li {
  margin: 0;
  padding: 0.5rem;
  list-style: none;
}
.section-content .column .logo-wrapper img {
  width: 100%;
  height: auto;
}
.blog-post {
  text-decoration: none;
}
.blog-post ul {
  list-style: disc;
  margin-left: 1em;
}
.blog-post ul li {
  margin: 0.5em 0;
}
.blog-title{
  padding-top: 0;
  padding-bottom: 1em;
}
.blog-subtitle {
  font-size: smaller;
  padding: 0 0 2em;
  margin: 0 0;
}
.blog-tags{
  margin: 1em 0;
  font-size: 14px;
}
.blog-read-more{
  display: block;
  text-decoration: underline;
}

.page-services-financial .column .column:first-child {
  flex-grow: 0.35;
}
.page-services-financial table {
  width: 100%;
  text-align: center;
}
.page-services-financial caption {
  padding: 1.25rem 0 2.15rem;
  font-family: 'publicsans-extrabold', Arial, sans-serif;
  font-size: 2.5rem;
  line-height: 1;
}
.page-services-financial tr {
  border-top: 1px solid #ccc;
}
.page-services-financial td {
  padding: 1rem 0;
  line-height: 1.5;
}

.page-process {
}
.page-process .section-graphic::after {
  display: none;
}
.page-process .section-content {
  padding: 0 0 4rem;
}
.page-process .section-content:last-of-type .column:last-child p:first-of-type {
  padding-top: 4.5rem;
}
.page-process .section-content:last-of-type .column hr {
  margin-bottom: 8rem;
}
.page-process .section-content .process-steps-list {
  counter-reset: list-counter;
}
.page-process .section-content .process-steps-list li {
  margin: 0;
  list-style: none;
  counter-increment: list-counter;
}
.page-process .section-content .process-steps-list a {
  display: block;
  position: relative;
  padding: 3rem 0 3rem 14rem;
  font-family: 'publicsans-medium', Arial, sans-serif;
  font-size: 3rem;
  text-decoration: none;
  background: transparent;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  transition: background-color 250ms linear, box-shadow 250ms linear;
}
.page-process .section-content .process-steps-list a:hover {
  background: #fff;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.25);
}
.page-process .section-content .process-steps-list a::before {
  width: 8rem;
  height: 8rem;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 2rem;
  margin: -4.1rem 0 0;
  line-height: 8rem;
  text-align: center;
  background: #fff;
  border: 2px solid #93debe;
  border-radius: 50%;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.25);
  content: counter(list-counter);
}

.page-process-moneyscript {
}
.page-process-moneyscript .columns .columns {
  padding-bottom: 2rem;
}

.page-about-team {
}
.page-about-team .section-content {
  margin-bottom: 6rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #ccc;
  opacity: 0;
  animation: fade-in-left 0.5s forwards alternate 1 0.1s;
}
.page-about-team .section-content .column:last-child {
  padding-top: 0;
  flex-grow: 2.5;
}
.page-about-team .section-content:last-of-type {
  margin: 0;
  padding: 0;
  border: 0;
}
.page-about-team .section-content .column:last-child:not(:first-child) h1 {
  padding-bottom: 1rem;
}
.page-about-team .section-content .column:last-child:not(:first-child) h2 + h3 {
  padding-top: 0;
  padding-bottom: 4rem;
}
.page-about-team .section-content .column:first-child:not(:last-child) > img,
.page-about-team .section-content .column:first-child:not(:last-child) p > img {
  width: 100%;
  margin: 0;
  border: 1px solid #3d3d3d;
}

.page-about-bio {
}
.page-about-bio h2 {
  padding-bottom: 2rem;
  font-family: 'publicsans-extrabold', Arial, sans-serif;
  font-size: 2.5rem;
  line-height: 1;
}
.page-about-bio h3 {
  font-size: 2rem;
}
.page-about-bio blockquote {
  font-style: italic;
}
.page-about-bio .section-content .column:first-child {
  flex-grow: 0.6;
}
.page-about-bio .section-content .column:first-child:not(:last-child) p > img,
.page-about-bio .section-content .column:first-child:not(:last-child) > img {
  width: 100%;
  max-width: 50rem;
  margin: 0;
}
.page-about-bio .section-content .column .columns {
  margin: 0 -2rem;
}
.page-about-bio .section-content .column .column {
  padding: 0 2rem;
  flex-grow: 1;
  font-size: 1.6rem;
  text-align: center;
}
.page-about-bio .section-content .column .column > img,
.page-about-bio .section-content .column .column:first-child:not(:last-child) > img,
.page-about-bio .section-content .column .column:last-child:not(:first-child) > img {
  max-width: 100%;
  margin: 2rem 0;
}
.page-about-bio .section-content .column .column a[target='_blank'] {
  text-decoration: none;
}
.page-about-bio .section-content .column .column a[target='_blank']:hover {
  text-decoration: underline;
}
.page-about-bio .section-content .column .column a[target='_blank'] img {
  max-width: 100%;
  margin: 2rem 0;
}

.page-about-bio .social-navigation {
  margin: -2rem 0 1rem;
}
.page-about-bio .social-navigation ul li {
  margin: 0 1rem 0 0;
  padding: 0;
}
.page-about-bio .social-item {
  width: 3rem;
  height: 3rem;
}

.page-moneyscript-form .section-graphic {
  min-height: 54rem;
}
.form {
  margin: -1.25rem auto 0;
}
.form-active .form {
  margin-top: 0;
}
.form-reminder {
  display: inline-block;
}
.form .columns {
  margin: 0 -1rem;
}
.form .column {
  padding: 0 1rem;
}
.form-active .column-intro {
  display: none;
}
.form-active .form-intro {
  display: none;
}
.form h3 {
  padding-bottom: 4rem;
  opacity: 0;
  animation: fade-in-left 0.5s forwards alternate 1 0.2s;
}
.form-label {
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin: 2rem 0 0;
  padding: 1.6rem 0 0 1.6rem;
  background: #fff;
  border: 1px solid #3d3d3d;
}
.form-label[data-valid='false'] {
  border-color: #ce0000;
}
.form-label span {
  font-size: 1.6rem;
}
.form-label input[type='text'],
.form-label input[type='email'],
.form-label input[type='tel'],
.form-label select,
.form-label textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 0.5rem 1.6rem 2.1rem 0;
  border: 0;
  background: transparent;
  box-shadow: none;
  font-size: 2rem;
  appearance: none;
}
.form-label select {
  padding-bottom: 0.85rem;
  margin: 0 0 1rem;
}
.form .button {
  margin: 2rem 2rem 2rem 0;
  opacity: 0;
  animation: fade-in-left 0.5s forwards alternate 1 0.6s;
}
.form-active .form .button.moneyscript-contact-link {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 18rem;
  margin: auto;
  font-family: Arial;
}
.form-active .form .button.moneyscript-contact-link::before {
  background: url('images/icon-contact.svg') no-repeat center center;
  display: inline-block;
  content: '';
  width: 3rem;
  height: 3rem;
}
.form-active .form .button {
  margin: 2rem;
}
.form .button-submit {
  display: none;
}
.form-complete .form .button-next {
  display: none;
}
.form-label-inline {
  display: inline-block;
  padding: 2rem 2rem 0;
  font-size: 1.6rem;
  font-family: 'publicsans-medium', Arial, sans-serif;
  cursor: pointer;
}
.form-label-inline input {
  width: 2rem;
  height: 2rem;
  position: relative;
  top: 0.2rem;
  font-size: 9rem;
}
.form-questions {
  display: none;
  text-align: center;
}
.form-active .form-questions {
  display: block;
}
.form-active.form-complete .form-questions,
.form-active.form-error .form-questions {
  display: none;
}
.form-fieldset {
  display: none;
  opacity: 0;
  animation: fade-in-left 0.5s forwards alternate 1 0.4s;
}
.form-fieldset:first-of-type {
  display: block;
}
.form-legend {
  display: block;
  width: 100%;
  padding-bottom: 2rem;
  font-size: 3rem;
}
.form-count {
  padding: 4rem 0 2rem;
  font-family: 'publicsans-light', Arial, sans-serif;
}
.form .form-message {
  padding: 1rem 0;
  opacity: 0;
  transition: opacity 0.5s ease-out;
}
.form .form-success,
.form .form-error {
  display: none;
  text-align: center;
}
.form-complete .form .form-success,
.form-error .form .form-error {
  display: block;
}

.section-contact {
  background: #d7f2f640;
}
.section-contact .column:first-child,
.section-contact .column ul,
.section-contact .column h1,
.section-contact .column h2 {
  animation-name: fade-in-up;
}
.section-contact .column:last-child {
  flex: 1.25 1 0;
  animation-name: fade-in;
  animation-duration: 2s;
}
.section-contact .column h2 {
  padding-bottom: 1rem;
  font-family: 'publicsans-bold', Arial, sans-serif;
  font-size: 2.5rem;
  color: #50bbff;
}
.section-contact .column h2 a {
  text-decoration: none;
}
.section-contact .column h2 a:hover {
  text-decoration: underline;
}
.section-contact .column ul {
  padding-bottom: 2rem;
}
.section-contact .column p {
  padding-bottom: 2rem;
}
.section-contact .column ul li {
  margin: 0 0 2rem;
  list-style: none;
}
.section-contact .column ul ul li {
  margin: 0;
}
.section-contact .button-view {
  display: block;
  padding: 0;
  font-size: 1.4rem;
  border: 0 none;
  background: transparent;
  cursor: pointer;
  appearance: none;
}
.section-contact .button-view:hover {
  text-decoration: underline;
}
.section-contact .button-view::after {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  margin-left: 1rem;
  position: relative;
  top: 0.75rem;
  background-image: url('images/icon-arrow-right-blue.svg');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: contain;
  content: '';
  transform: translate3d(0, 0, 0);
  transition: transform 250ms ease-out;
}
.section-contact .button-view:hover::after {
  transform: translate3d(0.5rem, 0, 0);
}

.map-column {
  min-height: 60rem;
  position: relative;
}
.location-column {
}
.location-container {
  max-height: 60rem;
  overflow: auto;
}
.location-state {
  width: 100%;
  border-bottom: 1px solid #ccc;
  margin-bottom: 2rem;
}
.location-state:last-child {
  border-bottom: 0;
}
.location-wrapper {
  display: flex;
  padding-bottom: 2rem;
}
.location-item {
  flex: 1 1 0;
}

#map {
  width: auto;
  height: auto;
  position: absolute;
  z-index: 1;
  top: -4rem;
  right: -2rem;
  bottom: -4rem;
  left: 0;
}

.section-content .column p .centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.section-content .column:first-child:not(:last-child) p > .centered,
.section-content .column:first-child:not(:last-child) > .centered {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.footer {
  font-size: 1.4rem;
}
.footer .wrapper {
  flex-direction: column;
}
.footer-logo {
  display: block;
  width: 26rem;
}
.footer-logo img {
  width: 100%;
}
.footer-content {
  display: flex;
  padding: 4rem 0;
  line-height: 1.5;
}
.footer-content p {
  padding-bottom: 2rem;
}
.footer-content .column {
  width: 25%;
}
.footer-content a:hover {
  text-decoration: none;
}
.footer-navigation {
  display: flex;
}
.footer-navigation ul {
  padding-right: 4rem;
}
.footer-navigation-item {
  display: inline-block;
  padding: 0 0 1rem;
  text-decoration: none;
}
.footer-navigation-item[aria-current='page'],
.footer-navigation-item.current {
  font-family: 'publicsans-bold', Arial, sans-serif;
  color: #50bbff;
}
.footer-content .footer-navigation-item:hover {
  text-decoration: underline;
}
.footer-legal {
  padding: 2rem 0;
}
.footer-social li {
  display: inline-block;
  padding: 1rem 0.25rem;
}
.footer-social-item {
  display: block;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background: #dbf8fc;
  font-size: 1rem;
  text-align: center;
  line-height: 6rem;
  text-decoration: none;
  transition: background-color 250ms ease-out;
}
.footer-social-item:hover {
  background: #ecf9fb;
}
.footer-social-item img {
  display: inline-block;
  width: auto;
  height: 40%;
  margin: 27.5% 0 0;
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  transition: transform 250ms ease-out;
}
.footer-social-item:hover img {
  transform: translate3d(0, 0, 0) scale3d(1.1, 1.1, 1.1);
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translate3d(0, 10rem, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fade-in-left {
  0% {
    opacity: 0;
    transform: translate3d(10rem, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fade-in-scale {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale3d(0, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
}

@media screen and (max-width: 1440px) {
  .section-hero .hero-image {
    width: 50vw;
    height: 50vw;
  }

  .section-image {
    width: 60vw;
  }
}

@media screen and (max-width: 1280px) {
  html {
    font-size: 8px;
  }
}

@media screen and (max-width: 1023px) {
  .main {
    padding: 12.5rem 0 0;
  }

  .header.active {
    z-index: 3;
  }

  .header .columns {
    flex-direction: column;
  }
  .header-navigation ul,
  .header-social {
    display: none;
  }
  .header-menu {
    width: 5rem;
    height: 5rem;
    top: 1.5rem;
    right: 4rem;
  }
  .header-menu,
  .header.active .header-navigation ul {
    display: block;
  }
  .header-navigation {
    margin-top: 0;
  }
  .header-navigation ul {
    margin: 0 -6rem;
    padding-top: 2rem;
    background: #fff;
  }
  .header-navigation li {
    display: block;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #ccc;
    text-align: left;
  }
  .header-navigation li:last-child,
  .header-navigation li:last-child .navigation-item {
    padding-right: 1rem;
  }
  .navigation-item {
    padding: 1rem;
  }
  .header.active .header-social {
    display: block;
    position: absolute;
    z-index: 1;
    top: 9rem;
    right: 2rem;
    margin: 0;
  }

  .sub-navigation {
    position: relative;
    top: auto;
    right: auto;
    padding: 0 0 4rem;
  }

  .header-logo {
    display: block;
    width: 18rem;
    margin: 1rem 0;
  }

  .section-graphic {
    padding: 4rem 0;
  }

  .section-hero {
    min-height: auto;
    padding-bottom: 10rem;
  }
  .section-hero .column:first-child {
    display: none;
  }
  .section-hero .column:last-child {
    flex-grow: 1;
  }

  .section-process ol {
    flex-direction: column;
  }
  .section-process ol::before {
    display: none;
  }
  .section-process ol li {
    margin: 0 4rem 14rem;
  }
  .section-process ol li:last-child {
    margin-bottom: 0;
  }

  .subsection-process-gameplan,
  .subsection-process-lifeplan {
    flex-direction: column;
  }
  .section-image {
    display: none;
  }

  .grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer-content .column {
    width: auto;
  }
  .footer-content {
    flex-direction: column;
  }
  .footer-navigation {
    flex-direction: column;
  }
  .footer-logo {
    display: block;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 10px;
  }

  h1,
  .section-hero h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 3rem;
  }

  .main {
    padding-top: 10rem;
  }

  .wrapper {
    padding: 0 2rem;
  }

  .header-menu {
    width: 4rem;
    height: 4rem;
    top: 0.75rem;
    right: 2rem;
  }
  .header-navigation ul {
    margin: 0 -2rem;
  }

  .sub-navigation {
    margin-top: -2rem;
    padding-bottom: 3rem;
    text-align: left;
  }
  .sub-navigation li {
    display: block;
    padding: 0;
  }
  .sub-navigation .navigation-item {
    padding: 1rem 0;
  }

  .header-logo,
  .footer-logo {
    width: 14rem;
  }

  .button,
  .button-contact,
  .button-form,
  .button-phone {
    padding: 2rem;
    font-size: 1.5rem;
  }
  .button-contact,
  .button-form {
    padding-left: 6.5rem;
  }
  .button-phone {
    padding-left: 5.5rem;
  }
  .button-contact::before,
  .button-form::before,
  .button-phone::before {
    width: 2.5rem;
    height: 2.5rem;
    left: 2rem;
  }

  .columns {
    flex-direction: column;
  }
  .column-left,
  .column-right {
    display: block;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .subsection-process-gameplan h2,
  .subsection-process-lifeplan h2 {
    font-size: 3rem;
  }

  .section-content h1 {
    padding-bottom: 4rem;
  }
  .section-content .columns {
    margin: 0;
  }
  .section-content .column {
    padding: 0;
  }
  .section-content .column img,
  .section-content .column p > img,
  .section-content .column:first-child:not(:last-child) img,
  .section-content .column:first-child:not(:last-child) p > img,
  .section-content .column:last-child:not(:first-child) img,
  .section-content .column:last-child:not(:first-child) p > img {
    max-width: 100%;
    margin: 0;
  }
  .section-blog .blog-wrapper{
    float: none;
    width: 100%;
  }
  .section-blog .blog-sidebar{
    display: none;
  }
  .section-contact .columns {
    flex-direction: column-reverse;
  }

  .page-services-financial table {
    margin-bottom: 4rem;
  }

  .page-about-bio .section-content .column .columns {
    margin: 0;
  }
  .page-about-bio .section-content .column .column {
    padding: 0;
  }
  .page-about-bio .section-content .column .column img,
  .page-about-bio .section-content .column .column:last-child:not(:first-child) img,
  .page-about-bio .section-content .column .column:first-child:not(:last-child) > img,
  .page-about-bio .section-content .column .column:last-child:not(:first-child) > img,
  .page-about-bio .section-content .column .column > img {
    width: 100%;
    max-width: 24rem;
    margin: 2rem auto;
  }

  .section-content blockquote p {
    margin-bottom: 4rem;
    font-size: 2.5rem;
  }

  .form {
    margin: -2rem auto 0;
  }
  .form-label-inline {
    display: block;
    padding-left: 0;
  }
  .form-questions {
    text-align: left;
  }
  .form-count {
    padding-left: 1rem;
  }
  .form-active .form .button {
    margin: 2rem 2rem 0 0;
  }

  .map-column {
    min-height: auto;
  }
  .location-column {
    margin-top: 6rem;
  }
  .location-container {
    max-height: none;
  }
  .location-container ul {
    flex-direction: column;
  }

  #map {
    left: -2rem;
    bottom: auto;
    height: 20rem;
  }

  .section-process p {
    font-size: 2rem;
  }

  .subsection-process-gameplan {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 480px) {
  .section-hero {
    padding-bottom: 4rem;
  }
  .section-hero p {
    font-size: 2rem;
  }

  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
